// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/roundup_logo.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".screen.invite-view{padding-top:60px;text-align:left}.screen.invite-view .invite-header p{margin:0}.screen.invite-view .roundup-logo{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:137px 14px;background-repeat:no-repeat;background-position:50%;width:137px;height:14px;margin:0 0 20px}.screen.invite-view .forgot-password{font-size:13px}", ""]);
// Exports
module.exports = exports;
