<template>
	<screen size="small" class="invite-view">
		<template slot="body">
			<div class="invite-header">
				<div class="roundup-logo"></div>
				<h2>Accept Invite</h2>
			</div>
			<div class="invite-content">
				<div class="alert alert-info">
					You've been invited to help manage a RoundUp Nonprofit
					Account. Please enter a password to be used with your new
					account.
				</div>
				<form v-on:submit.prevent="onSubmit">
					<ui-label>
						<ui-input
							v-model="password"
							placeholder="Password"
							type="password"
						/>
					</ui-label>
					<ui-label>
						<ui-button native-type="submit" :loading="isLoading"
							>Accept Invite</ui-button
						>
					</ui-label>
				</form>
			</div>
		</template>
	</screen>
</template>

<script>
import { mapActions } from 'vuex'
import Screen from '../../ui/Screen'
import UiButton from '../../ui/Button'
import UiInput from '../../ui/Input'
import UiLabel from '../../ui/Label'

export default {
	name: 'login-view',
	components: { UiButton, UiInput, UiLabel, Screen },
	props: ['uuid'],
	data() {
		return {
			isLoading: false,
			password: ''
		}
	},
	methods: {
		...mapActions(['acceptInvite']),
		onSubmit() {
			this.isLoading = true
			this.acceptInvite({ code: this.uuid, password: this.password })
				.then(data => {
					this.isLoading = false
					this.$router.push({ name: 'reports' })
				})
				.catch(({ message }) => {
					this.isLoading = false
					this.setAlert({
						alertText: message,
						showAlert: true,
						alertColor: 'error'
					})
				})
		}
	}
}
</script>

<style lang="scss">
@import '../../../styles/variables';

.screen {
	&.invite-view {
		padding-top: 60px;
		text-align: left;
		.invite-header {
			p {
				margin: 0px;
			}
		}
		.roundup-logo {
			background: url('../../../assets/roundup_logo.svg');
			background-size: 137px 14px;
			background-repeat: no-repeat;
			background-position: center;
			width: 137px;
			height: 14px;
			margin: 0px 0px 20px;
		}
		.forgot-password {
			font-size: 13px;
		}
	}
}
</style>
